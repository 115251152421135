.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: linear-gradient(to right, #041E41, #410433);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0px 2px 3px rgb(0, 0, 0); /* Add box-shadow effect */
  }